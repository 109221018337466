<template>
    <div class="modal-purchase-product">
        <div class="container">
            <div class="textbox">
                <div class="title f-24 p-t-16 m-l-20 m-r-20 m-b-24">
                    상품 선택
                    <i class="material-icons i-right f-30" @click="onClose()">close</i>
                </div>
            </div>
            <div class="item-list-container">
                <h3 class="m-t-40 m-l-20 m-b-16">{{ gender === 'male' ? '남성 회원 기간권' : '여성 회원 기간권' }}</h3>
                <div
                    class="item-list"
                    v-for="item in productList.filter(p => p.code.includes('unlimited'))"
                    :key="item.id"
                    :class="[item.code]"
                    @click="onClickItem(item)"
                >
                    <div class="product-item" :class="[item.code, item.$$selected ? 'selected' : '']" :gender="gender">
                        <div
                            class="image-tag"
                            v-if="item.code !== 'prepaid_male_1' && item.code !== 'prepaid_female_1'"
                        >
                            <img class="img-detail" :src="imageTag(item)" alt="" />
                        </div>
                        <div class="item-content">
                            <div
                                class="name f-17 m-t-28"
                                :class="'unlimited'"
                                v-html="$translate(item.code.toUpperCase())"
                            />
                            <div class="per-price f-8 m-t-14">
                                <span>
                                    <span class="price-per-amount f-20">{{ pricePerAmount(item) }}</span>
                                    / 1개월
                                </span>
                            </div>
                        </div>
                        <div class="price-description f-7">
                            <div class="pre-price">정가</div>
                            <div class="selling-price m-t-6">할인가</div>
                        </div>
                        <div class="prices">
                            <div class="pre-price">
                                <span class="price f-12">
                                    {{ Number(item.original_price * (10 / 11)).toLocaleString() }}원
                                </span>
                            </div>
                            <div class="selling-price">
                                <span class="price f-16 m-t-4">
                                    {{ Number(item.selling_price * (10 / 11)).toLocaleString() }}원
                                </span>
                                <span class="discount-rate f-7"> ({{ discountRate(item) }}) </span>
                            </div>
                        </div>
                        <div class="check-circle m-r-20">
                            <img :src="checkboxImg(item)" alt="" height="24px" />
                        </div>
                    </div>
                </div>
            </div>

            <div class="item-list-container" v-if="$store.getters.me.id < 9708">
                <h3 class="m-t-40 m-l-20 m-b-16">{{ gender === 'male' ? '남성 회원 만남권' : '여성 회원 만남권' }}</h3>
                <div
                    class="item-list"
                    v-for="item in productList.filter(p => !p.code.includes('unlimited'))"
                    :key="item.id"
                    :class="[item.code, gender]"
                    @click="onClickItem(item)"
                >
                    <div
                        class="product-item"
                        :class="[item.code, item.$$selected ? 'selected' : '', gender]"
                        :gender="gender"
                    >
                        <div
                            class="image-tag"
                            v-if="item.code !== 'prepaid_male_1' && item.code !== 'prepaid_female_1'"
                        >
                            <img class="img-detail" :src="imageTag(item)" alt="" />
                        </div>
                        <div class="item-content">
                            <div
                                class="name f-17 m-t-28"
                                :class="gender"
                                v-html="$translate(item.code.toUpperCase())"
                            />
                            <div class="per-price f-8 m-t-14">
                                <span>
                                    <span class="price-per-amount f-20">{{ pricePerAmount(item) }}</span>
                                    / 1장
                                </span>
                            </div>
                        </div>
                        <div class="price-description f-7">
                            <div class="pre-price">정가</div>
                            <div class="selling-price m-t-6">할인가</div>
                        </div>
                        <div class="prices">
                            <div class="pre-price">
                                <span class="price f-12">
                                    {{ Number(item.original_price * (10 / 11)).toLocaleString() }}원
                                </span>
                            </div>
                            <div class="selling-price">
                                <span class="price f-16 m-t-4">
                                    {{ Number(item.selling_price * (10 / 11)).toLocaleString() }}원
                                </span>
                                <span class="discount-rate f-7"> ({{ discountRate(item) }}) </span>
                            </div>
                        </div>
                        <div class="check-circle m-r-20">
                            <img :src="checkboxImg(item)" alt="" height="24px" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="bottom-button">
            <button class="btn-send-reason f-18" :class="{ disabled: disabled }" @click="onClickPurchase()">
                구매하기
            </button>
        </div>
    </div>
</template>

<script>
// import PurchaseProductItem from '@/routes/prepay/components/PurchaseProductItem'

export default {
    name: 'ModalPurchaseProduct',
    props: ['options'],
    // components: { PurchaseProductItem },
    data: () => ({
        disabled: true,
        selectedValue: null,
    }),
    watch: {
        selectedValue() {
            if (this.selectedValue) {
                this.selectedValue.$$selected = true
                this.disabled = false
                this.$emit('disabled', this.disabled)
            } else {
                this.selectedValue.$$selected = false
                this.disabled = true
                this.$emit('disabled', true)
            }
        },
    },
    computed: {
        productList() {
            const productList = this.options.products.map(v => ({ ...v, $$selected: false }))
            return productList
        },
        gender() {
            if (this.$store.getters.me.gender === 0) {
                return 'male'
            } else {
                return 'female'
            }
        },
    },
    mounted() {},
    methods: {
        pricePerAmount(item) {
            console.log(item)
            const price = item.selling_price * (10 / 11)
            let amount = 0
            if (item.code.includes('prepaid') || item.ptype === 'ticket_plan') {
                amount = item.amount
            } else if (item.code.includes('unlimited')) {
                amount = item.valid_date / 30
            }
            return (Math.round(price / amount) / 10000).toFixed(1)
        },
        discountRate(item) {
            return Math.round((item.discount / (item.original_price * (10 / 11))) * 100) + '% 할인'
        },
        checkboxImg(item) {
            if (item.$$selected) {
                if (item.code.includes('unlimited')) {
                    return require(`@/assets/images/icons/purchase_checked_unlimited.png`)
                } else if (this.gender === 'male') {
                    return require(`@/assets/images/icons/purchase_checked_male.png`)
                } else {
                    return require(`@/assets/images/icons/purchase_checked_female.png`)
                }
            } else {
                if (this.gender === 'male') {
                    return require(`@/assets/images/icons/purchase_male.png`)
                } else {
                    return require(`@/assets/images/icons/purchase_female.png`)
                }
            }
        },
        imageTag(item) {
            if ((item.code === 'ticket_10' || item.code === 'prepaid_male_3') && this.gender === 'male') {
                return require(`@/assets/images/icons/good_price_male.png`)
            } else if ((item.code === 'ticket_10' || item.code === 'prepaid_female_3') && this.gender === 'female') {
                return require(`@/assets/images/icons/good_price_female.png`)
            } else if (item.code.includes('A_6month') || item.code.includes('B_6month')) {
                return require(`@/assets/images/icons/good_price_unlimited.png`)
            } else if (
                item.code.includes('_5') ||
                item.code.includes('A_3month') ||
                item.code.includes('B_3month') ||
                item.code === 'prepaid_female_2' ||
                item.code === 'prepaid_male_2'
            ) {
                return require(`@/assets/images/icons/popular_price.png`)
            } else {
                return ''
            }
        },
        onClickItem(item) {
            console.log(item)
            console.log('selected', item.$$selected)
            this.$set(item, '$$selected', !item.$$selected)
            setTimeout(() => {
                if (this.selectedValue && this.selectedValue === item) {
                    this.selectedValue = null
                    this.disabled = true
                    this.$emit('disabled', true)
                } else {
                    this.selectedValue = item
                }
                // this.$emit('disabled', false)
                this.productList.forEach(o => this.$set(o, '$$selected', false))
            }, 200)
            this.searchValue = ''
        },
        async onClickPurchase() {
            try {
                if (this.disabled) {
                    this.$modal.basic({
                        body: '구매할 상품을 선택해주세요.',
                        buttons: [
                            {
                                label: 'CONFIRM',
                                class: 'btn-primary',
                            },
                        ],
                    })
                } else {
                    this.$store.commit('setSelectedProduct', this.selectedValue)
                    this.$store.commit('setSelectedPlan', this.selectedValue)
                    this.$router.push({ name: 'PaymentMethodsPage', params: { categoryName: this.selectedValue } })

                    this.$emit('close')
                }
            } catch (e) {
                this.$toast.error(e)
                console.log(e)
            }
        },
        onClose() {
            this.$emit('close')
        },
    },
}
</script>
<style scoped lang="scss">
.container {
    .title {
        position: relative;
        font-weight: 600;

        i {
            position: absolute;
            right: 0;
        }
    }

    .item-list-container {
        .product-item {
            position: relative;
            display: flex;
            margin: 12px 20px;
            background: #ffffff;
            height: 80px;
            border-radius: 8px;

            &.unlimited_male_A_1month,
            &.unlimited_male_B_1month,
            &.unlimited_female_A_1month,
            &.unlimited_female_B_1month {
                &.selected {
                    background: #b598d2;
                }
                height: 100px;
                border: 1px solid #b598d2;
            }

            &.unlimited_male_A_3month,
            &.unlimited_male_B_3month,
            &.unlimited_female_A_3month,
            &.unlimited_female_B_3month {
                &.selected {
                    background: #b598d2;
                }

                height: 100px;
                border: 1px solid #9565c5;
                .image-tag {
                    position: absolute;
                    background: #9565c5;
                    border-top-left-radius: 8px;
                    border-bottom-right-radius: 8px;
                    width: 45px;
                    height: 19px;
                    @include center;

                    .img-detail {
                        width: 30px;
                    }
                }
            }

            &.unlimited_male_A_6month,
            &.unlimited_male_B_6month,
            &.unlimited_female_A_6month,
            &.unlimited_female_B_6month {
                &.selected {
                    background: #b598d2;
                }

                height: 100px;
                border: 1px solid #663399;
                .image-tag {
                    position: absolute;
                    background: #663399;
                    border-top-left-radius: 8px;
                    border-bottom-right-radius: 8px;
                    width: 45px;
                    height: 19px;
                    @include center;

                    .img-detail {
                        width: 35px;
                    }
                }
            }

            &.ticket_10 {
                &.male {
                    &.selected {
                        background: #cfd3ed;
                    }
                    height: 100px;
                    border: 1px solid #2143d4;
                    .image-tag {
                        position: absolute;
                        background: #2143d4;
                        border-top-left-radius: 8px;
                        border-bottom-right-radius: 8px;
                        width: 45px;
                        height: 19px;
                        @include center;

                        .img-detail {
                            width: 35px;
                        }
                    }
                }
                &.female {
                    &.selected {
                        background: #ffe9e9;
                    }
                    height: 100px;
                    border: 1px solid #ff8c8c;
                    .image-tag {
                        position: absolute;
                        background: #ff8c8c;
                        border-top-left-radius: 8px;
                        border-bottom-right-radius: 8px;
                        width: 45px;
                        height: 19px;
                        @include center;

                        .img-detail {
                            width: 35px;
                        }
                    }
                }
            }

            &.prepaid_male_3 {
                &.male {
                    &.selected {
                        background: #cfd3ed;
                    }
                    height: 100px;
                    border: 1px solid #2143d4;
                    .image-tag {
                        position: absolute;
                        background: #2143d4;
                        border-top-left-radius: 8px;
                        border-bottom-right-radius: 8px;
                        width: 45px;
                        height: 19px;
                        @include center;

                        .img-detail {
                            width: 35px;
                        }
                    }
                }
            }

            &.prepaid_female_3 {
                &.female {
                    &.selected {
                        background: #ffe9e9;
                    }
                    height: 100px;
                    border: 1px solid #ff8c8c;
                    .image-tag {
                        position: absolute;
                        background: #ff8c8c;
                        border-top-left-radius: 8px;
                        border-bottom-right-radius: 8px;
                        width: 45px;
                        height: 19px;
                        @include center;
                        .img-detail {
                            width: 35px;
                        }
                    }
                }
            }

            &.ticket_5 {
                &.male {
                    &.selected {
                        background: #cfd3ed;
                    }
                    height: 100px;
                    border: 1px solid #a8b9ff;
                    .image-tag {
                        position: absolute;
                        background: #a8b9ff;
                        border-top-left-radius: 8px;
                        border-bottom-right-radius: 8px;
                        width: 45px;
                        height: 19px;
                        @include center;

                        .img-detail {
                            width: 30px;
                        }
                    }
                }
                &.female {
                    &.selected {
                        background: #ffe9e9;
                    }
                    height: 100px;
                    border: 1px solid #ffaaaa;
                    .image-tag {
                        position: absolute;
                        background: #ffaaaa;
                        border-top-left-radius: 8px;
                        border-bottom-right-radius: 8px;
                        width: 45px;
                        height: 19px;
                        @include center;

                        .img-detail {
                            width: 30px;
                        }
                    }
                }
            }

            &.prepaid_male_2 {
                &.male {
                    &.selected {
                        background: #cfd3ed;
                    }
                    height: 100px;
                    border: 1px solid #a8b9ff;
                    .image-tag {
                        position: absolute;
                        background: #a8b9ff;
                        border-top-left-radius: 8px;
                        border-bottom-right-radius: 8px;
                        width: 45px;
                        height: 19px;
                        @include center;

                        .img-detail {
                            width: 30px;
                        }
                    }
                }
            }

            &.prepaid_female_2 {
                &.female {
                    &.selected {
                        background: #ffe9e9;
                    }
                    height: 100px;
                    border: 1px solid #ffaaaa;
                    .image-tag {
                        position: absolute;
                        background: #ffaaaa;
                        border-top-left-radius: 8px;
                        border-bottom-right-radius: 8px;
                        width: 45px;
                        height: 19px;
                        @include center;

                        .img-detail {
                            width: 30px;
                        }
                    }
                }
            }

            &.ticket_3 {
                &.selected {
                    background: #cfd3ed;
                }
                border: 1px solid #ced8ff;
                &.female {
                    &.selected {
                        background: #ffe9e9;
                    }
                    border: 1px solid #ffc2c2;
                }
                .name {
                    margin-top: 16px !important;
                }
                .pre-price {
                    margin-top: 16px !important;
                }
            }

            &.ticket_1 {
                &.selected {
                    background: #cfd3ed;
                }
                border: 1px solid #ced8ff;
                &.female {
                    &.selected {
                        background: #ffe9e9;
                    }
                    border: 1px solid #ffc2c2;
                }
                .name {
                    margin-top: 16px !important;
                }
                .pre-price {
                    margin-top: 16px !important;
                }
            }

            &.prepaid_male_1 {
                &.selected {
                    background: #cfd3ed;
                }
                border: 1px solid #ced8ff;
                .name {
                    margin-top: 16px !important;
                }
                .pre-price {
                    margin-top: 16px !important;
                }
            }

            &.prepaid_female_1 {
                &.selected {
                    background: #ffe9e9;
                }
                border: 1px solid #ffc2c2;
                .name {
                    margin-top: 16px !important;
                }
                .pre-price {
                    margin-top: 16px !important;
                }
            }

            .item-content {
                margin: 0 10px 0 20px;
                .name {
                    font-weight: 700;

                    &.unlimited {
                        color: #663399;
                    }

                    &.male {
                        color: #2143d4;
                    }

                    &.female {
                        color: #ff8c8c;
                    }
                }

                .per-price {
                    color: #030068;
                    line-height: 12px;
                    font-weight: 500;

                    .price-per-amount {
                        font-weight: 600;
                    }
                }
            }

            .price-description {
                // margin: 0 20px;
                // position: absolute;
                // right: 0;
                margin-right: 10px;
                text-align: left;
                color: #5c5c77;
                display: inline-block;
                align-self: flex-end;
                padding-bottom: 20px;
                .pre-price {
                    align-items: center;
                    color: #8d8da0;
                    .price {
                        font-weight: 400;
                    }
                }

                .selling-price {
                    color: #5c5c77;
                    .price {
                        font-weight: 400;
                    }
                }
            }

            .prices {
                // margin: 0 20px;
                // position: absolute;
                // right: 0;
                text-align: left;
                color: #5c5c77;
                display: inline-block;
                align-self: flex-end;
                padding-bottom: 16px;

                .discount-rate {
                    color: #181f39;
                    font-size: 7px;
                    margin: 4px 0;
                    font-weight: 400;
                }

                .pre-price {
                    align-items: center;
                    color: #8d8da0;
                    .price {
                        font-weight: 400;
                        text-decoration: line-through;
                    }
                }

                .selling-price {
                    color: #5c5c77;
                    .price {
                        font-weight: 600;
                    }
                }
            }

            .check-circle {
                height: 100%;
                position: absolute;
                right: 0;
                @include center;
            }
        }

        .description {
            color: #696969;
            line-height: 19.2px;
        }
        ul {
            // @include center;

            li {
                @include center;
                padding: 17px;
                color: #888888;
                size: 14px;
                background: white;
                margin-bottom: 8px;
                border-radius: 8px;
                border: 1px solid #efdfd2;
                font-weight: 400;

                &.selected {
                    color: #151360;
                    border: 1px solid #151360;
                    font-weight: 600;
                }
            }
        }
    }
    // .bottom-button {
    //     padding: 12px 20px 20px 20px;
    //     position: absolute;
    //     bottom: 0;
    //     .btn-send-reason {
    //         height: 48px;
    //         width: 100%;
    //         border: none;
    //         border-radius: 36px;
    //         background: #151360;
    //         color: white;

    //         &.disabled {
    //             background: #d7d5d1;
    //         }
    //     }
    // }
}
</style>
